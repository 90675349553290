import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { axisClasses } from '@mui/x-charts/ChartsAxis';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import LinearProgress from '@mui/material/LinearProgress';
import { formatDate } from '../../../functions/utils';

// Register necessary components from Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement
);

const options = {
    responsive: true,
    scales: {
        x: {
            stacked: true, // Stack bars on the x-axis
            title: {
                display: true,
                text: 'Date', // Label for the X-axis
            },
        },
        y: {
            stacked: true, // Stack bars on the y-axis
            title: {
                display: true,
                text: 'Amount (R)', // Label for the X-axis
            },
        },
    },
};

const CashProfitabilityChart = ({ view_mode, chart_data, loading }) => {
    const [chartTitle, setChartTitle] = React.useState('Sales Data');
    const [chartData, setChartData] = React.useState([]);
    const [chartXLabels, setXLabels] = React.useState([]);

    const [posIncomeChartData, setIncomeChartData] = React.useState([]);
    const [expenseChartData, setExpenseChartData] = React.useState([]);

    const [chartLoading, setChartLoading] = React.useState(false);

    React.useEffect(() => {
        setChartLoading(loading)
    }, [loading]);

    // Manage Changes in view_mode
    React.useEffect(() => {
        if (view_mode === 'daily') {
            setChartTitle('Income vs Expenses (Daily)');
        } else if (view_mode === 'weekly') {
            setChartTitle('Income vs Expenses (Weekly)');
        } else if (view_mode ===  'monthly') {
            setChartTitle('Income vs Expenses (Monthly)');
        }
    }, [view_mode]);

    // Manage Changes in chartData
    React.useEffect(() => {
        if (chart_data !== undefined && Object.keys(chart_data).length > 0) {
            // setChartLoading(true)

            // Choose the right data fields
            if (view_mode === 'daily') {
                setChartData(chart_data.daily)
            }
            if (view_mode === 'weekly') {
                setChartData(chart_data.weekly)
            }
            if (view_mode === 'monthly') {
                setChartData(chart_data.monthly)
            }
        }
    }, [chart_data, chartTitle, view_mode]);


    // ---------------------------------------------------------
    // -------------- TRACK CHANGES IN CHART DATA --------------
    // ---------------------------------------------------------
    React.useEffect(() => {
        setXLabels( chartData.map(item => formatDate(item.date)) )
        setIncomeChartData( chartData.map(item => { return item.income_amount }) );
        setExpenseChartData( chartData.map(item => { return item.expense_amount }) );
    }, [chartData]);

    // return <Bar options={options} data={data} />;
    return (
        <Card sx={{ boxShadow: 5, background: '#eaeaea'}}>
            <CardContent>
                <Typography component="div" variant="h4" align="center">
                    {chartTitle}
                </Typography>
                <Divider />
                {
                    !chartLoading ? (
                        <Bar
                            options={options}
                            sx={{
                                marginTop:10,
                                [`.${axisClasses.left} .${axisClasses.label}`]: {
                                    transform: 'translate(-50px, 0)',
                                },
                            }}
                            data={{
                                labels: chartXLabels,
                                yLabels: { label: 'Amount (R)' },
                                datasets: [
                                    {
                                        type: 'bar', // Stacked bar dataset 1
                                        label: 'Income',
                                        data: posIncomeChartData,
                                        // backgroundColor: '#ff6600',
                                        backgroundColor: '#2E96FF',
                                        // stack: 'Stack 0',
                                    },
                                    {
                                        type: 'bar', // Stacked bar dataset 2
                                        label: 'Expenses',
                                        data: expenseChartData,
                                        // backgroundColor: '#2E96FF',
                                        backgroundColor: '#ff6600',
                                        stack: 'Stack 0',
                                    },
                                ]
                            }}
                        />
                    ) : (
                        <Box sx={{ width: '100%', height: '600px' }}>
                            <LinearProgress
                                sx={{
                                    height: 5, // Customize height
                                    marginTop: 5,
                                    backgroundColor: '#ff6600', // Background track color
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black', // Bar color
                                    },
                                }}
                            />
                        </Box>
                    )
                }
            </CardContent>
        </Card>
    );
};

export default CashProfitabilityChart;
